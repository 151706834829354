// import Swiper bundle with all modules installed
import Swiper from "swiper/bundle";

export function Accueil() {
  init();
  swiper1();
  swiper2();
  swiper3();
}

export function Products() {
  init();
  swiper1();
}

export function Product() {
  init();
  swiper1();
  swiper3();
}

function init() {
  var swiperNodes = "";
  var pagination = '<div class="swiper-pagination"></div>';
  var next_prev_buttons =
    '<div class="swiper-button-prev"></div><div class="swiper-button-next"></div>';
  var scrollbar = '<div class="swiper-scrollbar"></div>';
  var swiperNodes = swiperNodes.concat(pagination, next_prev_buttons);
  /* loop throw all swipers on the page */
  $(".swiper").each(function (index) {
    $(this).append(swiperNodes);
  });
}

function swiper1() {
  var mySwiper = new Swiper(".first-swiper", {
    mousewheel: {
      invert: true,
    },
    // Optional parameters
    slidesPerView: 3,
    spaceBetween: 50,
    loop: false,
    loopedSlides: 3,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next",
      prevEl: ".arrow-previous",
    },
    keyboard: {
      enabled: true,
    },
    mousewheel: {
      enabled: false,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */
        slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */
        slidesPerView: 2,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 2,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 3,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
    autoplay: {
      delay: 3000,
    },
    */
    /* uncomment if you want scrollbar
     scrollbar: {
        el: '.swiper-scrollbar',
        hide: true,
      },
    */
  });
}

function swiper2() {
  var testimonialsSwiper = new Swiper(".swiper-2", {
    mousewheel: {
      invert: true,
    },
    // Optional parameters
    slidesPerView: 2,
    spaceBetween: 50,
    loop: false,
    loopedSlides: 2,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next-2",
      prevEl: ".arrow-previous-2",
    },
    keyboard: {
      enabled: true,
    },
    mousewheel: {
      enabled: false,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */
        slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */
        slidesPerView: 1,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 2,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 2,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 2,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
    autoplay: {
      delay: 3000,
    },
    */
    /* uncomment if you want scrollbar
     scrollbar: {
        el: '.swiper-scrollbar',
        hide: true,
      },
    */
  });
}

function swiper3() {
  // swiper innovation
  var innovationSwiper = new Swiper(".swiper-3", {
    mousewheel: {
      invert: true,
    },
    // Optional parameters
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,
    loopedSlides: 1,
    speed: 300,
    // Enable lazy loading
    lazy: false,
    navigation: {
      nextEl: ".arrow-next-3",
      prevEl: ".arrow-previous-3",
    },
    keyboard: {
      enabled: true,
    },
    mousewheel: {
      enabled: false,
    },
    breakpoints: {
      0: {
        /* when window >=0px - webflow mobile portriat */
        slidesPerView: 1,
        spaceBetween: 15,
      },
      478: {
        /* when window >= 478px - webflow mobile landscape */
        slidesPerView: 1,
        spaceBetween: 15,
      },
      767: {
        /* when window >= 767px - webflow tablet */ slidesPerView: 1,
        spaceBetween: 30,
      },
      988: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 1,
        spaceBetween: 50,
      },
      1920: {
        /* when window >= 988px - webflow desktop */ slidesPerView: 1,
        spaceBetween: 50,
      },
    },
    /* uncomment if you want autoplay slider
                  autoplay: {
                    delay: 3000,
                  },
                  */
    /* uncomment if you want scrollbar
                   scrollbar: {
                      el: '.swiper-scrollbar',
                      hide: true,
                    },
                  */
  });
}
