//// JS MAIN FILE ////
const pageID = document.querySelector('html').getAttribute('data-wf-page')

import lightboxHandler from './modules/_lightboxHandler.js'
lightboxHandler();

// Import Webflow
import { webflow } from './modules/_webflow.js'
webflow();

// Import templates
import {
	Accueil as SwiperAccueil,
	Products as SwiperProducts,
	Product as SwiperProduct,
} from './templates/_swiper.js'

switch (pageID) {
	// If Page is Accueil
	case '640906afd8cc0c866bf7c304':
		SwiperAccueil()
		break
	// If Page is Products
	case '642203576649ab6453c00455':
		SwiperProducts()
		break
	// If Page is Product
	case '641c7fc25e6dc62d550d86cb':
		SwiperProduct()
		break
}

// Import gsap library
import { gsapLib } from './ink-gsap/_ink-gsap.js';
gsapLib();
import { envTags } from './ink-gsap/_envTags.js';
envTags();

// Import modules
