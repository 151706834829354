export default function lightboxHandler() {
	const videos = document.querySelectorAll('[lightbox-video]')
	const imgs = document.querySelectorAll('[lightbox-img]')

	LightboxVideos(videos)
	LightboxImgs(imgs)
}

function LightboxVideos(videos) {
	if (videos.length > 0) {
		const expression = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube(?:\-nocookie)?\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v|vi|shorts)\/))([^\?&"'>]+)/

		videos.forEach((item) => {
			if (item.getAttribute('lightbox-video')) {
				// Gets the youtube video id
				const videoId = expression.exec(
					item.getAttribute('lightbox-video')
				)[1]

				// Set video image
				const img = item.querySelector('img')
				img.src = `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`

				// Creates Webflow lightbox config
				CreateLightboxConfig(
					{
						items: [
							{
								url: `https://www.youtube.com/watch?v=${videoId}`,
								originalUrl: `https://www.youtube.com/watch?v=${videoId}`,
								width: 940,
								height: 528,
								thumbnailUrl: `https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
								html: `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0"></iframe>`,
								type: 'video',
							},
						],
						group: 'work',
					},
					item
				)
			}
		})
	}
}

// https://assets.sunnysunshineimport.com/1513408.jpg
function LightboxImgs(imgs) {
	if (imgs.length > 0) {
		imgs.forEach((item) => {
			if (item.getAttribute('lightbox-img')) {
				// Gets image data (fileName, url)
				const imgUrl = item.getAttribute('lightbox-img')
				const fileName = imgUrl.split('/')[
					(imgUrl.split('/').length -= 1)
				]

				// Creates Webflow lightbox config
				CreateLightboxConfig(
					{
						items: [
							{
								_id: 'example_img',
								origFileName: fileName,
								fileName: fileName,
								fileSize: 1432500,
								height: 1337,
								url: imgUrl,
								width: 1788,
								type: 'image',
							},
						],
						group: 'work',
					},
					item
				)
			}
		})
	}
}

function CreateLightboxConfig(config, parent) {
	const child = document.createElement('script')
	child.setAttribute('type', 'application/json')
	child.classList.add('w-json')
	child.innerHTML = JSON.stringify(config)
	parent.appendChild(child)
}
